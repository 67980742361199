import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import App from "../components/app"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Layer Outer Privacy Policy" />
    <div className="BasicPage">
      <App
        name="Layer Outer"
        imageName="/icon-layerouter.png"
        isInBeta={true}
      />
    </div>
  </Layout>
)

export default Page
